import { clamp } from "~/utils/clamp";

export default function (el) {
  const { y } = useWindowScroll();
  const { height } = useElementSize(el);

  let lastY = 0;
  let position = ref(0);

  const isFixed = computed(() => y.value > 0);
  const isScrolledPassed = computed(() => y.value > 200);

  watch(y, () => {
    const deltaY = y.value - lastY;

    if (deltaY === 0) return;

    lastY = y.value;

    position.value =
      window.scrollY <= 0 ? 0 : clamp(position.value - deltaY, -height.value, 0);
  });

  return {
    isFixed,
    isScrolledPassed,
    position
  }
}